/* Responsiveness */
/* Desktop ----------- */
@media only screen and (min-width: 771px) {
  *[data-show-only="mobile"] {
    display: none !important;
  }
  *[data-show-only="desktop"] {
    display: block;
  }
  *[data-mobile-only] {
    display: none !important;
  }
  *[data-timed-style="fade"][data-show-only="desktop"] {
    display: none;
  }
  *[data-timed-style="scale"][data-show-only="desktop"] {
    display: none;
  }
  *[data-timed-style="fade"][data-show-only="desktop"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="scale"][data-show-only="desktop"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="no-action"][data-show-only="desktop"].displayImportantTimed {
    display: block;
  }
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 0px) and (max-width: 770px) {
  /*html[max-width="400px"]{*/
  *[data-show-only="desktop"] {
    display: none !important;
  }
  *[data-show-only="mobile"] {
    display: block;
  }
  *[data-desktop-only] {
    display: none !important;
  }
  *[data-timed-style="fade"][data-show-only="mobile"] {
    display: none;
  }
  *[data-timed-style="scale"][data-show-only="mobile"] {
    display: none;
  }
  *[data-timed-style="fade"][data-show-only="mobile"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="scale"][data-show-only="mobile"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="no-action"][data-show-only="mobile"].displayImportantTimed {
    display: block;
  }

  /* Section Sizes */
  .fullContainer .containerInner,
  .fullContainer .containerInnerV2 {
    width: 100% !important;
    min-width: 320px;
  }

  .fullContainer .containerInner .containerInner,
  .fullContainer .containerInnerV2 .containerInnerV2,
  .wideContainer .containerInner .containerInner,
  .wideContainer .containerInnerV2 .containerInnerV2,
  .midWideContainer .containerInner .containerInner,
  .midWideContainer .containerInnerV2 .containerInnerV2,
  .midContainer .containerInner .containerInner,
  .midContainer .containerInnerV2 .containerInnerV2,
  .smallContainer .containerInner .containerInner,
  .smallContainer .containerInnerV2 .containerInnerV2,
  .containerInner .fullContainer,
  .containerInnerV2 .fullContainer,
  .containerInner .wideContainer,
  .containerInnerV2 .wideContainer,
  .containerInner .midWideContainer,
  .containerInnerV2 .midWideContainer,
  .containerInner .midContainer,
  .containerInnerV2 .midContainer,
  .containerInner .smallContainer,
  .containerInnerV2 .smallContainer {
    min-width: auto;
  }

  /* This is required because we are allowing sections to be within popups now */
  .containerModal .fullContainer .containerInner,
  .containerModal .fullContainer .containerInnerV2 {
    min-width: inherit;
  }

  .containerModal .wideContainer,
  .containerModal .midWideContainer,
  .containerModal .midContainer,
  .containerModal .smallContainer  {
    min-width: 100% !important;
  }

  .wideContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .midWideContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .midContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .smallContainer {
    width: 100% !important;
    min-width: 320px;
  }
  /* modal / popup */
  /*.smallContainer.containerModal {
		margin-left: 0px;
	}*/
  /* columns */
  .col-inner {
    /*padding-top: 10px !important;*/
    /*padding-bottom: 10px !important;*/
  }
  .innerContent {
    padding: 0px !important;
    width: 100%;
  }
  .row {
    /*padding-left: 5px !important;*/
    /*padding-right: 5px !important;*/
  }
  .col_right {
    margin-top: 20px !important;
  }
  /* elements */
  /* ------ Video - make 100% */
  .de-video-block {
    width: 100% !important;
  }
  /* ------ Countdown - make standard size */
  .wideCountdownSize4 .countdown-amount {
    font-size: 18px;
    width: 57px;
  }
  .wideCountdownSize4 .countdown-period {
    font-size: 10px;
  }
  .de-nav {
    text-align: center !important;
  }
  .elScreenshot_text {
    float: none !important;
    width: 100% !important;
    text-align: center !important;
  }
  .elScreenshot_text_padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .elScreenshot_image {
    float: none !important;
    width: 100% !important;
  }
  .hsSize72 {
    font-size: 48px;
  }
  /* modal */
  .elImageWrapper {
    text-align: center !important;
  }
  .elText {
    /*text-align: center !important;*/
    /* will now respect alignment */
  }
  .containerInner,
  .containerInnerV2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .row,
  .col-inner {
    margin-left: auto !important;
    margin-right: auto !important;
    width: auto !important;
  }
  .elButtonSize3,
  .elButtonSize4 {
    font-size: 21px;
    border-radius: 5px;
    padding: 15px 35px;
  }
  .elSharing,
  .elBTN {
    text-align: center;
  }
  .elBulletList {
    /*text-align: center;*/
  }
  .elScreenshot_text_body,
  .elScreenshot_text_headline {
    text-align: center !important;
  }
  .elScreenshot_image {
    text-align: center !important;
    margin-top: 20px;
  }
  .elButtonInline {
    display: block;
  }
  .elTextWrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .iframeBlocker {
    display: none !important;
  }
  .ccNumberWrap {
    float: left;
    width: 100%;
  }
  .ccCVCWrap {
    float: left;
    width: 100%;
    padding-left: 0;
  }
  .elAffStats_views:before {
    content: "";
  }
  .bgCover {
    background-attachment: scroll !important;
  }
  /* order summary alignments */
  .elOrderProductOptinProductName {
    /*width: 70% !important;*/
    width: 100% !important;
  }
  .elOrderProductOptinPrice {
    width: 30% !important;
    text-align: right;
  }
  /* force video unlocker hide on mobile */
  .elvideounlock_videoPauseBlocker {
    display: none !important;
  }
  .bgW100 {
    background-size: cover !important;
    -webkit-background-size: cover !important;
    /*background-attachment: fixed !important;*/
    background-repeat: repeat repeat !important;
    background-position: center center;
  }
  [data-page-element="RowContainer/V1"] {
    flex-direction: column,
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"] {
    flex-direction: row;

    & > .col_right {
      margin-top: 0px !important;
    }
    & > .col-sm-1,
    & > .col-md-1,
    & > .col-lg-1,
    & > .col-xs-1,
    & > .col-xs-2,
    & > .col-sm-2,
    & > .col-md-2,
    & > .col-lg-2,
    & > .col-xs-3,
    & > .col-sm-3,
    & > .col-md-3,
    & > .col-lg-3,
    & > .col-xs-4,
    & > .col-sm-4,
    & > .col-md-4,
    & > .col-lg-4,
    & > .col-xs-5,
    & > .col-sm-5,
    & > .col-md-5,
    & > .col-lg-5,
    & > .col-xs-6,
    & > .col-sm-6,
    & > .col-md-6,
    & > .col-lg-6,
    & > .col-xs-7,
    & > .col-sm-7, 
    & > .col-md-7, 
    & > .col-lg-7, 
    & > .col-xs-8, 
    & > .col-sm-8, 
    & > .col-md-8, 
    & > .col-lg-8, 
    & > .col-xs-9, 
    & > .col-sm-9, 
    & > .col-md-9, 
    & > .col-lg-9, 
    & > .col-xs-10, 
    & > .col-sm-10, 
    & > .col-md-10, 
    & > .col-lg-10, 
    & > .col-xs-11, 
    & > .col-sm-11, 
    & > .col-md-11, 
    & > .col-lg-11, 
    & > .col-xs-12, 
    & > .col-sm-12, 
    & > .col-md-12, 
    & > .col-lg-12 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
    & > .col-xs-12 {
      width: 100%;
    }
    & > .col-xs-11 {
      width: 91.66666667%;
    }
    & > .col-xs-10 {
      width: 83.33333333%;
    }
    & > .col-xs-9 {
      width: 75%;
    }
    & > .col-xs-8 {
      width: 66.66666667%;
    }
    & > .col-xs-7 {
      width: 58.33333333%;
    }
    & > .col-xs-6 {
      width: 50%;
    }
    & > .col-xs-5 {
      width: 41.66666667%;
    }
    & > .col-xs-4 {
      width: 33.33333333%;
    }
    & > .col-xs-3 {
      width: 25%;
    }
    & > .col-xs-2 {
      width: 16.66666667%;
    }
    & > .col-xs-1 {
      width: 8.33333333%;
    }
    & > .col-xs-pull-12 {
      right: 100%;
    }
    & > .col-xs-pull-11 {
      right: 91.66666667%;
    }
    & > .col-xs-pull-10 {
      right: 83.33333333%;
    }
    & > .col-xs-pull-9 {
      right: 75%;
    }
    & > .col-xs-pull-8 {
      right: 66.66666667%;
    }
    & > .col-xs-pull-7 {
      right: 58.33333333%;
    }
    & > .col-xs-pull-6 {
      right: 50%;
    }
    & > .col-xs-pull-5 {
      right: 41.66666667%;
    }
    & > .col-xs-pull-4 {
      right: 33.33333333%;
    }
    & > .col-xs-pull-3 {
      right: 25%;
    }
    & > .col-xs-pull-2 {
      right: 16.66666667%;
    }
    & > .col-xs-pull-1 {
      right: 8.33333333%;
    }
    & > .col-xs-pull-0 {
      right: auto;
    }
    & > .col-xs-push-12 {
      left: 100%;
    }
    & > .col-xs-push-11 {
      left: 91.66666667%;
    }
    & > .col-xs-push-10 {
      left: 83.33333333%;
    }
    & > .col-xs-push-9 {
      left: 75%;
    }
    & > .col-xs-push-8 {
      left: 66.66666667%;
    }
    & > .col-xs-push-7 {
      left: 58.33333333%;
    }
    & > .col-xs-push-6 {
      left: 50%;
    }
    & > .col-xs-push-5 {
      left: 41.66666667%;
    }
    & > .col-xs-push-4 {
      left: 33.33333333%;
    }
    & > .col-xs-push-3 {
      left: 25%;
    }
    & > .col-xs-push-2 {
      left: 16.66666667%;
    }
    & > .col-xs-push-1 {
      left: 8.33333333%;
    }
    & > .col-xs-push-0 {
      left: auto;
    }
    & > .col-xs-offset-12 {
      margin-left: 100%;
    }
    & > .col-xs-offset-11 {
      margin-left: 91.66666667%;
    }
    & > .col-xs-offset-10 {
      margin-left: 83.33333333%;
    }
    & > .col-xs-offset-9 {
      margin-left: 75%;
    }
    & > .col-xs-offset-8 {
      margin-left: 66.66666667%;
    }
    & > .col-xs-offset-7 {
      margin-left: 58.33333333%;
    }
    & > .col-xs-offset-6 {
      margin-left: 50%;
    }
    & > .col-xs-offset-5 {
      margin-left: 41.66666667%;
    }
    & > .col-xs-offset-4 {
      margin-left: 33.33333333%;
    }
    & > .col-xs-offset-3 {
      margin-left: 25%;
    }
    & > .col-xs-offset-2 {
      margin-left: 16.66666667%;
    }
    & > .col-xs-offset-1 {
      margin-left: 8.33333333%;
    }
    & > .col-xs-offset-0 {
      margin-left: 0%;
    }
    & > .col-sm-1, 
    & > .col-sm-2, 
    & > .col-sm-3, 
    & > .col-sm-4, 
    & > .col-sm-5, 
    & > .col-sm-6, 
    & > .col-sm-7, 
    & > .col-sm-8, 
    & > .col-sm-9, 
    & > .col-sm-10, 
    & > .col-sm-11, 
    & > .col-sm-12 {
      float: left;
    }
    & > .col-sm-12 {
      width: 100%;
    }
    & > .col-sm-11 {
      width: 91.66666667%;
    }
    & > .col-sm-10 {
      width: 83.33333333%;
    }
    & > .col-sm-9 {
      width: 75%;
    }
    & > .col-sm-8 {
      width: 66.66666667%;
    }
    & > .col-sm-7 {
      width: 58.33333333%;
    }
    & > .col-sm-6 {
      width: 50%;
    }
    & > .col-sm-5 {
      width: 41.66666667%;
    }
    & > .col-sm-4 {
      width: 33.33333333%;
    }
    & > .col-sm-3 {
      width: 25%;
    }
    & > .col-sm-2 {
      width: 16.66666667%;
    }
    & > .col-sm-1 {
      width: 8.33333333%;
    }
    & > .col-sm-pull-12 {
      right: 100%;
    }
    & > .col-sm-pull-11 {
      right: 91.66666667%;
    }
    & > .col-sm-pull-10 {
      right: 83.33333333%;
    }
    & > .col-sm-pull-9 {
      right: 75%;
    }
    & > .col-sm-pull-8 {
      right: 66.66666667%;
    }
    & > .col-sm-pull-7 {
      right: 58.33333333%;
    }
    & > .col-sm-pull-6 {
      right: 50%;
    }
    & > .col-sm-pull-5 {
      right: 41.66666667%;
    }
    & > .col-sm-pull-4 {
      right: 33.33333333%;
    }
    & > .col-sm-pull-3 {
      right: 25%;
    }
    & > .col-sm-pull-2 {
      right: 16.66666667%;
    }
    & > .col-sm-pull-1 {
      right: 8.33333333%;
    }
    & > .col-sm-pull-0 {
      right: auto;
    }
    & > .col-sm-push-12 {
      left: 100%;
    }
    & > .col-sm-push-11 {
      left: 91.66666667%;
    }
    & > .col-sm-push-10 {
      left: 83.33333333%;
    }
    & > .col-sm-push-9 {
      left: 75%;
    }
    & > .col-sm-push-8 {
      left: 66.66666667%;
    }
    & > .col-sm-push-7 {
      left: 58.33333333%;
    }
    & > .col-sm-push-6 {
      left: 50%;
    }
    & > .col-sm-push-5 {
      left: 41.66666667%;
    }
    & > .col-sm-push-4 {
      left: 33.33333333%;
    }
    & > .col-sm-push-3 {
      left: 25%;
    }
    & > .col-sm-push-2 {
      left: 16.66666667%;
    }
    & > .col-sm-push-1 {
      left: 8.33333333%;
    }
    & > .col-sm-push-0 {
      left: auto;
    }
    & > .col-sm-offset-12 {
      margin-left: 100%;
    }
    & > .col-sm-offset-11 {
      margin-left: 91.66666667%;
    }
    & > .col-sm-offset-10 {
      margin-left: 83.33333333%;
    }
    & > .col-sm-offset-9 {
      margin-left: 75%;
    }
    & > .col-sm-offset-8 {
      margin-left: 66.66666667%;
    }
    & > .col-sm-offset-7 {
      margin-left: 58.33333333%;
    }
    & > .col-sm-offset-6 {
      margin-left: 50%;
    }
    & > .col-sm-offset-5 {
      margin-left: 41.66666667%;
    }
    & > .col-sm-offset-4 {
      margin-left: 33.33333333%;
    }
    & > .col-sm-offset-3 {
      margin-left: 25%;
    }
    & > .col-sm-offset-2 {
      margin-left: 16.66666667%;
    }
    & > .col-sm-offset-1 {
      margin-left: 8.33333333%;
    }
    & > .col-sm-offset-0 {
      margin-left: 0%;
    }
    & > .col-md-1, 
    & > .col-md-2, 
    & > .col-md-3, 
    & > .col-md-4, 
    & > .col-md-5, 
    & > .col-md-6, 
    & > .col-md-7, 
    & > .col-md-8, 
    & > .col-md-9, 
    & > .col-md-10, 
    & > .col-md-11, 
    & > .col-md-12 {
      float: left;
    }
    & > .col-md-12 {
      width: 100%;
    }
    & > .col-md-11 {
      width: 91.66666667%;
    }
    & > .col-md-10 {
      width: 83.33333333%;
    }
    & > .col-md-9 {
      width: 75%;
    }
    & > .col-md-8 {
      width: 66.66666667%;
    }
    & > .col-md-7 {
      width: 58.33333333%;
    }
    & > .col-md-6 {
      width: 50%;
    }
    & > .col-md-5 {
      width: 41.66666667%;
    }
    & > .col-md-4 {
      width: 33.33333333%;
    }
    & > .col-md-3 {
      width: 25%;
    }
    & > .col-md-2 {
      width: 16.66666667%;
    }
    & > .col-md-1 {
      width: 8.33333333%;
    }
    & > .col-md-pull-12 {
      right: 100%;
    }
    & > .col-md-pull-11 {
      right: 91.66666667%;
    }
    & > .col-md-pull-10 {
      right: 83.33333333%;
    }
    & > .col-md-pull-9 {
      right: 75%;
    }
    & > .col-md-pull-8 {
      right: 66.66666667%;
    }
    & > .col-md-pull-7 {
      right: 58.33333333%;
    }
    & > .col-md-pull-6 {
      right: 50%;
    }
    & > .col-md-pull-5 {
      right: 41.66666667%;
    }
    & > .col-md-pull-4 {
      right: 33.33333333%;
    }
    & > .col-md-pull-3 {
      right: 25%;
    }
    & > .col-md-pull-2 {
      right: 16.66666667%;
    }
    & > .col-md-pull-1 {
      right: 8.33333333%;
    }
    & > .col-md-pull-0 {
      right: auto;
    }
    & > .col-md-push-12 {
      left: 100%;
    }
    & > .col-md-push-11 {
      left: 91.66666667%;
    }
    & > .col-md-push-10 {
      left: 83.33333333%;
    }
    & > .col-md-push-9 {
      left: 75%;
    }
    & > .col-md-push-8 {
      left: 66.66666667%;
    }
    & > .col-md-push-7 {
      left: 58.33333333%;
    }
    & > .col-md-push-6 {
      left: 50%;
    }
    & > .col-md-push-5 {
      left: 41.66666667%;
    }
    & > .col-md-push-4 {
      left: 33.33333333%;
    }
    & > .col-md-push-3 {
      left: 25%;
    }
    & > .col-md-push-2 {
      left: 16.66666667%;
    }
    & > .col-md-push-1 {
      left: 8.33333333%;
    }
    & > .col-md-push-0 {
      left: auto;
    }
    & > .col-md-offset-12 {
      margin-left: 100%;
    }
    & > .col-md-offset-11 {
      margin-left: 91.66666667%;
    }
    & > .col-md-offset-10 {
      margin-left: 83.33333333%;
    }
    & > .col-md-offset-9 {
      margin-left: 75%;
    }
    & > .col-md-offset-8 {
      margin-left: 66.66666667%;
    }
    & > .col-md-offset-7 {
      margin-left: 58.33333333%;
    }
    & > .col-md-offset-6 {
      margin-left: 50%;
    }
    & > .col-md-offset-5 {
      margin-left: 41.66666667%;
    }
    & > .col-md-offset-4 {
      margin-left: 33.33333333%;
    }
    & > .col-md-offset-3 {
      margin-left: 25%;
    }
    & > .col-md-offset-2 {
      margin-left: 16.66666667%;
    }
    & > .col-md-offset-1 {
      margin-left: 8.33333333%;
    }
    & > .col-md-offset-0 {
      margin-left: 0%;
    }
    & > .col-lg-1, 
    & > .col-lg-2, 
    & > .col-lg-3, 
    & > .col-lg-4, 
    & > .col-lg-5, 
    & > .col-lg-6, 
    & > .col-lg-7, 
    & > .col-lg-8, 
    & > .col-lg-9, 
    & > .col-lg-10, 
    & > .col-lg-11, 
    & > .col-lg-12 {
      float: left;
    }
    & > .col-lg-12 {
      width: 100%;
    }
    & > .col-lg-11 {
      width: 91.66666667%;
    }
    & > .col-lg-10 {
      width: 83.33333333%;
    }
    & > .col-lg-9 {
      width: 75%;
    }
    & > .col-lg-8 {
      width: 66.66666667%;
    }
    & > .col-lg-7 {
      width: 58.33333333%;
    }
    & > .col-lg-6 {
      width: 50%;
    }
    & > .col-lg-5 {
      width: 41.66666667%;
    }
    & > .col-lg-4 {
      width: 33.33333333%;
    }
    & > .col-lg-3 {
      width: 25%;
    }
    & > .col-lg-2 {
      width: 16.66666667%;
    }
    & > .col-lg-1 {
      width: 8.33333333%;
    }
    & > .col-lg-pull-12 {
      right: 100%;
    }
    & > .col-lg-pull-11 {
      right: 91.66666667%;
    }
    & > .col-lg-pull-10 {
      right: 83.33333333%;
    }
    & > .col-lg-pull-9 {
      right: 75%;
    }
    & > .col-lg-pull-8 {
      right: 66.66666667%;
    }
    & > .col-lg-pull-7 {
      right: 58.33333333%;
    }
    & > .col-lg-pull-6 {
      right: 50%;
    }
    & > .col-lg-pull-5 {
      right: 41.66666667%;
    }
    & > .col-lg-pull-4 {
      right: 33.33333333%;
    }
    & > .col-lg-pull-3 {
      right: 25%;
    }
    & > .col-lg-pull-2 {
      right: 16.66666667%;
    }
    & > .col-lg-pull-1 {
      right: 8.33333333%;
    }
    & > .col-lg-pull-0 {
      right: auto;
    }
    & > .col-lg-push-12 {
      left: 100%;
    }
    & > .col-lg-push-11 {
      left: 91.66666667%;
    }
    & > .col-lg-push-10 {
      left: 83.33333333%;
    }
    & > .col-lg-push-9 {
      left: 75%;
    }
    & > .col-lg-push-8 {
      left: 66.66666667%;
    }
    & > .col-lg-push-7 {
      left: 58.33333333%;
    }
    & > .col-lg-push-6 {
      left: 50%;
    }
    & > .col-lg-push-5 {
      left: 41.66666667%;
    }
    & > .col-lg-push-4 {
      left: 33.33333333%;
    }
    & > .col-lg-push-3 {
      left: 25%;
    }
    & > .col-lg-push-2 {
      left: 16.66666667%;
    }
    & > .col-lg-push-1 {
      left: 8.33333333%;
    }
    & > .col-lg-push-0 {
      left: auto;
    }
    & > .col-lg-offset-12 {
      margin-left: 100%;
    }
    & > .col-lg-offset-11 {
      margin-left: 91.66666667%;
    }
    & > .col-lg-offset-10 {
      margin-left: 83.33333333%;
    }
    & > .col-lg-offset-9 {
      margin-left: 75%;
    }
    & > .col-lg-offset-8 {
      margin-left: 66.66666667%;
    }
    & > .col-lg-offset-7 {
      margin-left: 58.33333333%;
    }
    & > .col-lg-offset-6 {
      margin-left: 50%;
    }
    & > .col-lg-offset-5 {
      margin-left: 41.66666667%;
    }
    & > .col-lg-offset-4 {
      margin-left: 33.33333333%;
    }
    & > .col-lg-offset-3 {
      margin-left: 25%;
    }
    & > .col-lg-offset-2 {
      margin-left: 16.66666667%;
    }
    & > .col-lg-offset-1 {
      margin-left: 8.33333333%;
    }
    & > .col-lg-offset-0 {
      margin-left: 0%;
    }
  }
}
/* custom breakpoints for section sizes */
/* Prevent Rows From Collasping On midContainer & smallContainers */
@media only screen and (min-width: 770px) and (max-width: 1170px) {
  .col-inner {
    /*padding-left: 20px !important;*/
    /*padding-right: 20px !important;*/
  }
  /* full */
  .fullContainer .col-md-1,
  .fullContainer .col-md-2,
  .fullContainer .col-md-3,
  .fullContainer .col-md-4,
  .fullContainer .col-md-5,
  .fullContainer .col-md-6,
  .fullContainer .col-md-7,
  .fullContainer .col-md-8,
  .fullContainer .col-md-9,
  .fullContainer .col-md-10,
  .fullContainer .col-md-11,
  .fullContainer .col-md-12 {
    float: left !important;
  }
  .fullContainer .col-md-12 {
    width: 100%;
  }
  .fullContainer .col-md-11 {
    width: 91.66666667%;
  }
  .fullContainer .col-md-10 {
    width: 83.33333333%;
  }
  .fullContainer .col-md-9 {
    width: 75%;
  }
  .fullContainer .col-md-8 {
    width: 66.66666667%;
  }
  .fullContainer .col-md-7 {
    width: 58.33333333%;
  }
  .fullContainer .col-md-6 {
    width: 50%;
  }
  .fullContainer .col-md-5 {
    width: 41.66666667%;
  }
  .fullContainer .col-md-4 {
    width: 33.33333333%;
  }
  .fullContainer .col-md-3 {
    width: 25%;
  }
  .fullContainer .col-md-2 {
    width: 16.66666667%;
  }
  .fullContainer .col-md-1 {
    width: 8.33333333%;
  }
  /* wide */
  .wideContainer .col-md-1,
  .wideContainer .col-md-2,
  .wideContainer .col-md-3,
  .wideContainer .col-md-4,
  .wideContainer .col-md-5,
  .wideContainer .col-md-6,
  .wideContainer .col-md-7,
  .wideContainer .col-md-8,
  .wideContainer .col-md-9,
  .wideContainer .col-md-10,
  .wideContainer .col-md-11,
  .wideContainer .col-md-12 {
    float: left !important;
  }
  .wideContainer .col-md-12 {
    width: 100%;
  }
  .wideContainer .col-md-11 {
    width: 91.66666667%;
  }
  .wideContainer .col-md-10 {
    width: 83.33333333%;
  }
  .wideContainer .col-md-9 {
    width: 75%;
  }
  .wideContainer .col-md-8 {
    width: 66.66666667%;
  }
  .wideContainer .col-md-7 {
    width: 58.33333333%;
  }
  .wideContainer .col-md-6 {
    width: 50%;
  }
  .wideContainer .col-md-5 {
    width: 41.66666667%;
  }
  .wideContainer .col-md-4 {
    width: 33.33333333%;
  }
  .wideContainer .col-md-3 {
    width: 25%;
  }
  .wideContainer .col-md-2 {
    width: 16.66666667%;
  }
  .wideContainer .col-md-1 {
    width: 8.33333333%;
  }
  /* mid wide */
  .midWideContainer .col-md-1,
  .midWideContainer .col-md-2,
  .midWideContainer .col-md-3,
  .midWideContainer .col-md-4,
  .midWideContainer .col-md-5,
  .midWideContainer .col-md-6,
  .midWideContainer .col-md-7,
  .midWideContainer .col-md-8,
  .midWideContainer .col-md-9,
  .midWideContainer .col-md-10,
  .midWideContainer .col-md-11,
  .midWideContainer .col-md-12 {
    float: left !important;
  }
  .midWideContainer .col-md-12 {
    width: 100%;
  }
  .midWideContainer .col-md-11 {
    width: 91.66666667%;
  }
  .midWideContainer .col-md-10 {
    width: 83.33333333%;
  }
  .midWideContainer .col-md-9 {
    width: 75%;
  }
  .midWideContainer .col-md-8 {
    width: 66.66666667%;
  }
  .midWideContainer .col-md-7 {
    width: 58.33333333%;
  }
  .midWideContainer .col-md-6 {
    width: 50%;
  }
  .midWideContainer .col-md-5 {
    width: 41.66666667%;
  }
  .midWideContainer .col-md-4 {
    width: 33.33333333%;
  }
  .midWideContainer .col-md-3 {
    width: 25%;
  }
  .midWideContainer .col-md-2 {
    width: 16.66666667%;
  }
  .midWideContainer .col-md-1 {
    width: 8.33333333%;
  }
  /*  mid */
  .midContainer .col-md-1,
  .midContainer .col-md-2,
  .midContainer .col-md-3,
  .midContainer .col-md-4,
  .midContainer .col-md-5,
  .midContainer .col-md-6,
  .midContainer .col-md-7,
  .midContainer .col-md-8,
  .midContainer .col-md-9,
  .midContainer .col-md-10,
  .midContainer .col-md-11,
  .midContainer .col-md-12 {
    float: left !important;
  }
  .midContainer .col-md-12 {
    width: 100%;
  }
  .midContainer .col-md-11 {
    width: 91.66666667%;
  }
  .midContainer .col-md-10 {
    width: 83.33333333%;
  }
  .midContainer .col-md-9 {
    width: 75%;
  }
  .midContainer .col-md-8 {
    width: 66.66666667%;
  }
  .midContainer .col-md-7 {
    width: 58.33333333%;
  }
  .midContainer .col-md-6 {
    width: 50%;
  }
  .midContainer .col-md-5 {
    width: 41.66666667%;
  }
  .midContainer .col-md-4 {
    width: 33.33333333%;
  }
  .midContainer .col-md-3 {
    width: 25%;
  }
  .midContainer .col-md-2 {
    width: 16.66666667%;
  }
  .midContainer .col-md-1 {
    width: 8.33333333%;
  }
  /* small */
  .smallContainer .col-md-1,
  .smallContainer .col-md-2,
  .smallContainer .col-md-3,
  .smallContainer .col-md-4,
  .smallContainer .col-md-5,
  .smallContainer .col-md-6,
  .smallContainer .col-md-7,
  .smallContainer .col-md-8,
  .smallContainer .col-md-9,
  .smallContainer .col-md-10,
  .smallContainer .col-md-11,
  .smallContainer .col-md-12 {
    float: left !important;
  }
  .smallContainer .col-md-12 {
    width: 100%;
  }
  .smallContainer .col-md-11 {
    width: 91.66666667%;
  }
  .smallContainer .col-md-10 {
    width: 83.33333333%;
  }
  .smallContainer .col-md-9 {
    width: 75%;
  }
  .smallContainer .col-md-8 {
    width: 66.66666667%;
  }
  .smallContainer .col-md-7 {
    width: 58.33333333%;
  }
  .smallContainer .col-md-6 {
    width: 50%;
  }
  .smallContainer .col-md-5 {
    width: 41.66666667%;
  }
  .smallContainer .col-md-4 {
    width: 33.33333333%;
  }
  .smallContainer .col-md-3 {
    width: 25%;
  }
  .smallContainer .col-md-2 {
    width: 16.66666667%;
  }
  .smallContainer .col-md-1 {
    width: 8.33333333%;
  }
}
@media only screen and (max-width: 700px) {
  [data-mobile-font-size] li {
    font-size: inherit !important;
  }
  .elPageContent.elPageContent [data-mobile-font-size="10"] {
    font-size: 10px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="11"] {
    font-size: 11px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="12"] {
    font-size: 12px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="13"] {
    font-size: 13px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="14"] {
    font-size: 14px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="15"] {
    font-size: 15px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="16"] {
    font-size: 16px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="17"] {
    font-size: 17px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="18"] {
    font-size: 18px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="19"] {
    font-size: 19px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="20"] {
    font-size: 20px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="21"] {
    font-size: 21px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="22"] {
    font-size: 22px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="23"] {
    font-size: 23px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="24"] {
    font-size: 24px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="25"] {
    font-size: 25px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="26"] {
    font-size: 26px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="27"] {
    font-size: 27px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="28"] {
    font-size: 28px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="29"] {
    font-size: 29px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="30"] {
    font-size: 30px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="31"] {
    font-size: 31px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="32"] {
    font-size: 32px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="33"] {
    font-size: 33px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="34"] {
    font-size: 34px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="35"] {
    font-size: 35px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="36"] {
    font-size: 36px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="37"] {
    font-size: 37px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="38"] {
    font-size: 38px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="39"] {
    font-size: 39px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="40"] {
    font-size: 40px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="41"] {
    font-size: 41px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="42"] {
    font-size: 42px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="43"] {
    font-size: 43px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="44"] {
    font-size: 44px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="45"] {
    font-size: 45px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="46"] {
    font-size: 46px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="47"] {
    font-size: 47px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="48"] {
    font-size: 48px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="49"] {
    font-size: 49px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="50"] {
    font-size: 50px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="51"] {
    font-size: 51px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="52"] {
    font-size: 52px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="53"] {
    font-size: 53px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="54"] {
    font-size: 54px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="55"] {
    font-size: 55px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="56"] {
    font-size: 56px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="57"] {
    font-size: 57px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="58"] {
    font-size: 58px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="59"] {
    font-size: 59px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="60"] {
    font-size: 60px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="61"] {
    font-size: 61px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="62"] {
    font-size: 62px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="63"] {
    font-size: 63px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="64"] {
    font-size: 64px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="65"] {
    font-size: 65px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="66"] {
    font-size: 66px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="67"] {
    font-size: 67px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="68"] {
    font-size: 68px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="69"] {
    font-size: 69px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="70"] {
    font-size: 70px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="71"] {
    font-size: 71px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="72"] {
    font-size: 72px;
  }
}
/* fullContainer */
@media (max-width: 1170px) {
  .fullContainer .containerInner,
  .fullContainer .containerInnerV2 {
    width: 100% !important;
  }
}
/* midWideContainer */
@media (max-width: 960px) {
  .midWideContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .midWideContainer .containerInner,
  .midWideContainer .containerInnerV2 {
    width: 100% !important;
  }
}
/* midContainer */
@media only screen and (min-width: 650px) and (max-width: 720px) {
  .midContainer {
    width: 100% !important;
    min-width: 320px;
  }
}
/* smallContainer */
@media only screen and (min-width: 650px) and (max-width: 650px) {
  .smallContainer {
    width: 100% !important;
    min-width: 320px;
  }
}
/* HEIGHT - make popups - margin top */
/*@media only screen and (min-height : 0px) and (max-height : 750px) {

	#modalPopup{
		margin-top: 50px !important;
	}

}*/
.displayImportantTimed {
  display: block;
}
